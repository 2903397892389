import { GetSitecoreReportQuery } from '@typings/operations';

export const columnWiseReportData = (
  data: GetSitecoreReportQuery['reportData']
) => {
  const columnWiseReportsData = [];
  if (data && data?.length !== 0) {
    for (let i = 0; i < data?.length; i += 2) {
      columnWiseReportsData.push(data?.slice(i, i + 2));
    }
  }
  return columnWiseReportsData;
};
