import React from 'react';

import RequireLogin from '@components/Auth/RequireLogin';
import NotFound from '@components/Routing/NotFound';
import MembershipFoundationReport from '@presenters/web/pages/MembershipFoundationReport/MembershipFoundationReport';

import { FEATURE_SHAREPOINT, isEnabled } from '@utils/features';

const MembershipAndFoundationReports: React.FC = () => {
  const isSharepointEnabled = isEnabled(FEATURE_SHAREPOINT);

  if (!isSharepointEnabled) {
    return <NotFound />;
  }
  return (
    <RequireLogin>
      <MembershipFoundationReport />
    </RequireLogin>
  );
};

export default MembershipAndFoundationReports;
